<template>
  <BaseButtonSet
    label="Ajouter des produits"
    color="success"
    icon="fa fa-plus"
    @click="add = true"
  ></BaseButtonSet>
  <!--  Add Dialog-->
  <Dialog
    modal
    v-model:visible="add"
    header="Ajouter des produits"
    @show="onShow"
    ref="addDialog"
    maximizable
    :style="{ maxWidth: '92vw', maxHeight: '94vh' }"
    class="custom-dialog"
  >
    <template #default>
      <div class="p-fluid p-field multiselect-window">
        <label>Choisissez un/des produits</label>
        <MultiSelect
          v-model="selectedArticles"
          :options="articles"
          :loading="pending"
          optionLabel="name"
          placeholder="Choisissez"
          :filter="true"
          class="mt-1"
          id="custom-multiselect"
          dataKey="id"
        >
          <template #value="slotProps">
            <span v-if="!slotProps.value.length">Choisissez</span>
            <span v-else class="font-italic text-primary"
              >{{ slotProps.value.length }} produit{{
                slotProps.value.length > 1 ? "s" : ""
              }}
              selectionné{{ slotProps.value.length > 1 ? "s" : "" }}</span
            >
          </template>
          <template #option="slotProps">
            <div class="flex align-items-baseline">
              <span>{{ slotProps.option.name }}</span>
              <span class="text-sm text-primary ml-1"
                >{{ slotProps.option.capacity * 100 }}cl</span
              >
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field selected-window flex flex-column">
        <label class="mb-1">Produits selectionnés</label>
        <div v-if="selectedArticles.length > 0">
          <Chip
            v-for="item in selectedArticles"
            :key="item.id"
            class="custom-chip"
            removable
            @remove="onRemove(item.id)"
          >
            {{ item.name }} {{ item.capacity * 100 }}cl
          </Chip>
        </div>
        <p v-else class="text-sm font-italic text-center">Aucun produit</p>
      </div>
    </template>
    <template #footer>
      <div class="pt-3">
        <Button
          label="Retour"
          class="p-button-text p-button-secondary"
          @click="add = false"
        />
        <Button
          label="Ajouter"
          class="p-button-success"
          icon="pi pi-plus"
          iconPose="right"
          @click="onAdd"
          :disabled="!selectedArticles.length"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { ref } from "vue";
import useDatabase from "../../composables/useDatabase";
import { projectDatabase } from "../../firebase/config";
import { useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import useMercurial from "../../composables/useMercurial";

export default {
  props: ["areaId"],
  emits: ["updateSelectedArea"],
  setup(props, { emit }) {
    const route = useRoute();
    const { centerId, zoneId } = route.params;

    const toast = useToast();
    const { mercurialFolder } = useMercurial();

    const add = ref(false);
    const addDialog = ref();

    const selectedArticles = ref([]);

    const { docs: articles, pending } = useDatabase(
      `${mercurialFolder.value}/articles`
    ).getAll();

    const onShow = () => {
      addDialog.value.maximized = true;
    };

    const onRemove = (id) => {
      selectedArticles.value = selectedArticles.value.filter(
        (a) => a.id !== id
      );
    };

    const onAdd = async () => {
      if (selectedArticles.value.length) {
        let updates = {};
        selectedArticles.value.forEach((article) => {
          const newKey = projectDatabase
            .ref(
              `centers/${centerId}/zones/${zoneId}/areas/${props.areaId}/articles`
            )
            .push().key;
          // const rowRef = `centers/${centerId}/rows/${article.id}/${newKey}`;
          updates[
            `centers/${centerId}/zones/${zoneId}/areas/${props.areaId}/articles/${newKey}`
          ] = {
            articleId: article.id,
            position: 999,
            // rowRef: rowRef,
          };
          // updates[rowRef] = {
          //   total: 0,
          // };
        });
        await projectDatabase.ref().update(updates);
        emit("updateSelectedArea", props.areaId);
        selectedArticles.value = [];
        add.value = false;
        toast.add({
          severity: "info",
          detail: "Nouveaux produits ajoutés avec succès",
          life: 2500,
        });
      }
    };

    return {
      add,
      addDialog,
      onShow,
      onRemove,
      onAdd,
      pending,
      articles,
      selectedArticles,
    };
  },
};
</script>

<style lang="scss">
.multiselect-window {
  position: sticky;
  top: 0;
  background-color: white;
  padding-bottom: 1rem;
}
.custom-chip {
  background-color: $primary;
  color: white;
  font-size: 0.75rem;
  margin: 0.15rem;
}
.p-multiselect-panel {
  width: 50%;
  .p-multiselect-header .p-checkbox {
    display: none;
  }
}
</style>
