<template>
  <Pending v-if="pending" />
  <PageLayout v-else>
    <template #header>
      <BaseInplace v-model="zone.name" @edit="onEdit"></BaseInplace>
      <hr />
      <BaseLegend>
        <template v-if="!$route.query.board">
          <p>
            Dans cette section, vous pouvez ajouter, supprimer et/ou renommer
            vos rangements.
          </p>
          <p>
            Vous pouvez egalement ajouter, supprimer et/ou modifier l'ordre des
            produits dans chaque rangement.
          </p>
        </template>
        <template v-else
          >Positionnez vous sur le rangement souhaité avant de commencer votre
          saisie.
        </template>
      </BaseLegend>
      <div class="p-field mt-4">
        <label>Choisissez un rangement :</label>
        <div class="p-inputgroup mt-1">
          <Dropdown
            :options="areas"
            optionLabel="name"
            optionValue="id"
            v-model="selectedArea"
            placeholder="Selectionnez"
            @change="onUpdateSelectedArea(selectedArea)"
          ></Dropdown>
          <AreaActions
            :areaId="selectedArea"
            :area="areas.find((a) => a.id === selectedArea)"
            ref="areaActions"
            @updateSelectedArea="onUpdateSelectedArea"
          ></AreaActions>
        </div>
      </div>
    </template>
    <template #default>
      <keep-alive v-if="selectedArea">
        <component
          :is="'AreaRows'"
          :areaId="selectedArea"
          :key="selectedArea"
          @addProducts="openAddProducts"
        />
      </keep-alive>
      <BaseNotFound
        v-if="!zone.areas"
        @action="openAddArea"
        message="Aucun rangement trouvé"
        detail="Ajouter un rangement"
      />
    </template>
    <template #footer>
      <ActionsNav>
        <router-link
          custon
          v-slot="{ navigate }"
          :to="{ name: 'ZonesList', query: { board: $route.query.board } }"
        >
          <BaseButtonSet
            label="Retour"
            color="secondary"
            icon="fa fa-arrow-left"
            text
            rtl
            @click="navigate"
          ></BaseButtonSet>
        </router-link>
        <AddProducts
          v-if="selectedArea"
          ref="addProducts"
          :areaId="selectedArea"
        />
      </ActionsNav>
    </template>
  </PageLayout>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import useDatabase from "../../composables/useDatabase";
import { useToast } from "primevue/usetoast";

import AreaRows from "./_AreaRows";
import AddProducts from "./_AddProducts";
import AreaActions from "./_AreaActions";

export default defineComponent({
  components: { AreaRows, AddProducts, AreaActions },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { centerId, zoneId } = route.params;
    const toast = useToast();

    const { getOne, updateOne } = useDatabase(`centers/${centerId}/zones`);

    const { doc: zone, error, pending } = getOne(zoneId);

    const onEdit = async (data) => {
      const { error, pending } = await updateOne(zoneId, {
        name: data,
      });
      if (!pending.value && !error.value) {
        toast.add({
          severity: "info",
          summary: "Informations",
          detail: `La zone à bien été renommée !`,
          life: 2500,
        });
      }
    };

    const selectedArea = ref();

    watch(pending, () => {
      if (!pending.value && areas.value[0]) {
        selectedArea.value = route.params.areaId || areas.value[0].id;
        if (selectedArea.value) {
          router.replace({
            name: "AreaShow",
            params: { areaId: selectedArea.value },
            query: { board: route.query.board },
          });
        }
      }
    });

    const onUpdateSelectedArea = (areaId) => {
      if (areaId) {
        selectedArea.value = areaId;
        router.replace({
          name: "AreaShow",
          params: { areaId: areaId },
          query: { board: route.query.board },
        });
      } else if (zone.value.areas) {
        selectedArea.value = areas.value[0].id;
        router.replace({
          name: "AreaShow",
          params: { areaId: areas.value[0].id },
          query: { board: route.query.board },
        });
      } else {
        selectedArea.value = null;
        router.replace({
          name: "AreasList",
          query: { board: route.query.board },
        });
      }
    };

    const areas = computed(() => {
      const arr = [];
      if (zone.value.areas) {
        Object.entries(zone.value.areas).forEach((area) => {
          arr.push({ id: area[0], ...area[1] });
        });
      }
      return arr;
    });

    const areaActions = ref();
    const openAddArea = () => {
      areaActions.value.add = true;
    };

    const addProducts = ref();
    const openAddProducts = () => {
      addProducts.value.add = true;
    };

    return {
      zone,
      error,
      pending,
      onEdit,
      areas,
      selectedArea,
      areaActions,
      openAddArea,
      addProducts,
      openAddProducts,
      onUpdateSelectedArea,
    };
  },
});
</script>

<style lang="scss" scoped></style>
