<template>
  <Button
    icon="fa fa-plus"
    class="p-button-success p-button-text"
    @click="add = true"
  />
  <template v-if="area">
    <Button
      icon="fa fa-pen-alt"
      class="p-button-text p-button-secondary"
      @click="openEdit"
    />
    <Button
      icon="fa fa-trash-alt"
      class="p-button-danger p-button-text"
      @click="onRemove"
    />
  </template>
  <!--  Edit Dialog-->
  <Dialog
    v-model:visible="edit"
    modal
    :header="`Renommer ${area?.name}`"
    :breakpoints="{ '576px': '90vw' }"
    :style="{ width: '50vw' }"
  >
    <template #default>
      <form id="edit-form" @submit.prevent="onEdit" class="p-field">
        <div class="p-fluid">
          <label for="name">Nouveau nom</label>
          <InputText id="name" class="mt-2" v-model="newName" />
        </div>
      </form>
    </template>
    <template #footer>
      <Button
        label="Retour"
        class="p-button-text p-button-secondary"
        @click="edit = false"
      />
      <Button
        label="Renommer"
        class="p-button-primary"
        type="submit"
        form="edit-form"
        :disabled="!newName || newName === area.name"
      />
    </template>
  </Dialog>
  <!--  Add Dialog-->
  <Dialog
    v-model:visible="add"
    modal
    header="Ajouter un rangement"
    :breakpoints="{ '576px': '90vw' }"
    :style="{ width: '50vw' }"
  >
    <template #default>
      <form id="add-form" @submit.prevent="onAdd" class="p-field">
        <div class="p-fluid">
          <label for="name">Nom</label>
          <InputText
            id="name"
            class="mt-2"
            placeholder="Choisissez un nom"
            v-model="name"
          />
        </div>
      </form>
    </template>
    <template #footer>
      <Button
        label="Retour"
        class="p-button-text p-button-secondary"
        @click="add = false"
      />
      <Button
        label="Ajouter"
        class="p-button-success"
        type="submit"
        form="add-form"
        :disabled="!name"
      />
    </template>
  </Dialog>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "vue";
import { useRoute } from "vue-router";
import useDatabase from "../../composables/useDatabase";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { projectDatabase } from "../../firebase/config";

export default defineComponent({
  props: ["area", "areaId"],
  emits: ["updateSelectedArea"],
  setup(props, { emit }) {
    const route = useRoute();
    const { centerId, zoneId } = route.params;

    const confirm = useConfirm();
    const toast = useToast();

    const add = ref(false);
    const edit = ref(false);

    const { addOne, updateOne, removeOne } = useDatabase(
      `centers/${centerId}/zones/${zoneId}/areas`
    );

    const openEdit = () => {
      newName.value = props.area.name;
      edit.value = true;
    };

    const newName = ref("");
    const onEdit = async () => {
      if (newName.value !== props.area.name) {
        await updateOne(props.area.id, {
          name: newName.value,
        });
        newName.value = "";
        toast.add({
          severity: "info",
          detail: `Rangement renommé !`,
          life: 2500,
        });
      }
      edit.value = false;
    };

    const input = reactive({
      name: "",
    });
    const onAdd = async () => {
      const { doc } = await addOne(input);
      add.value = false;
      toast.add({
        severity: "success",
        detail: `Nouveau rangement ${input.name} ajouté !`,
        life: 2500,
      });
      input.name = "";
      emit("updateSelectedArea", doc.value.id);
    };

    const onRemove = () => {
      const areaToRemove = props.area;
      confirm.require({
        header: `Supprimer ${areaToRemove.name}`,
        message: "Êtes-vous certain de vouloir supprimer le rangement ?",
        acceptClass: "p-button-danger",
        acceptLabel: "Supprimer",
        rejectClass: "p-button-text p-button-secondary",
        rejectLabel: "Retour",
        accept: async () => {
          const rowsToRemove = [];
          const updates = {};
          if (areaToRemove.articles) {
            Object.entries(areaToRemove.articles).forEach((a) => {
              rowsToRemove.push(a[1].rowRef);
            });
            rowsToRemove.forEach((ref) => {
              updates[ref] = null;
            });
            await projectDatabase.ref().update(updates);
          }
          await removeOne(areaToRemove.id);
          toast.add({
            severity: "info",
            detail: `Le rangement ${areaToRemove.name} à été supprimé !`,
            life: 2500,
          });
          emit("updateSelectedArea", null);
        },
      });
    };

    return {
      add,
      edit,
      onAdd,
      onRemove,
      ...toRefs(input),
      onEdit,
      newName,
      openEdit,
    };
  },
});
</script>

<style lang="scss" scoped></style>
